var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rulebox" },
    [
      _c("center", { staticClass: "centertitle" }, [
        _vm._v(_vm._s(_vm.GLOBAL) + "交易平台用户协议")
      ]),
      _vm._v(
        " " +
          _vm._s(_vm.GLOBAL) +
          "行情报价平台的运营方为深圳" +
          _vm._s(_vm.GLOBAL) +
          "贵金属有限责任公司。 在您提交申请使用之前，请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。 本协议内容包括以下条款及本平台已经发布的或将来可能发布的各类规则。修改各类具体规则并在本网站相关系统板块发布，无需另行单独通知您。 如果您使用" +
          _vm._s(_vm.GLOBAL) +
          "交易平台，或以任何行为实际使用、享受" +
          _vm._s(_vm.GLOBAL) +
          "行情报价平台的服务，即表示您充分阅读、充分理解并接受了本协议的全部内容，并同意接受本协议各项条款的约束。 由于互联网技术高速发展，本协议列明的条款并不能完整罗列并覆盖您与平台所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。 因此，未来平台法律声明及隐私权政策、规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用平台服务，视为您同意上述补充协议。 "
      ),
      _c("div", [_vm._v("一、" + _vm._s(_vm.GLOBAL) + "服务内容")]),
      _vm._v(
        " " +
          _vm._s(_vm.GLOBAL) +
          "行情报价平台提供手机微信公众号端口， ios端" +
          _vm._s(_vm.GLOBAL) +
          "app和Android端口" +
          _vm._s(_vm.GLOBAL) +
          "app，等各种形式服务软件（具体以" +
          _vm._s(_vm.GLOBAL) +
          "提供的为准） ，" +
          _vm._s(_vm.GLOBAL) +
          "会不断丰富您使用本服务的终端、形式等，如您已注册使用一种形式的服务，则可以以同一账号使用其他版本服务，本协议自动适用于您对所有版本的软件和服务的使用。 本平台提供的服务包括但不仅限于：黄金报价、铂金报价、钯金报价、白银报价，贵金属原料等产品的交易、行情资讯、服务网点等，具体以平台功能为准。 "
      ),
      _c("div", [_vm._v("二、" + _vm._s(_vm.GLOBAL) + "账户")]),
      _vm._v(
        " 1、用户在成功提交使用申请及注册后，" +
          _vm._s(_vm.GLOBAL) +
          "交易平台会为每位用户开通一个账户作为其使用" +
          _vm._s(_vm.GLOBAL) +
          "交易平台服务的唯一身份标识，该账户经平台或平台商家授权后可用于登录" +
          _vm._s(_vm.GLOBAL) +
          "旗下的其他应用。用户需自行妥善保管该账户的用户名和密码，该账户下所有操作均视为用户行为，因用户保管不善导致账号密码遗失泄露的，造成用户损失的，" +
          _vm._s(_vm.GLOBAL) +
          "交易平台及运营方不承担任何责任。 2、用户保证向" +
          _vm._s(_vm.GLOBAL) +
          "交易平台提供的任何资料、注册信息的真实性、正确性及完整性，包括但不限于邮箱、联系电话、法人企业的证照、门店信息或个人等。用户保证" +
          _vm._s(_vm.GLOBAL) +
          "交易平台可以通过上述联系方式与用户进行有效联系；当上述资料发生变更时，及时更新用户资料，符合及时、详尽、准确的要求。否则，造成的一切后果由用户自行承担。 3、由于您使用的需要，您可通过合法账号邀请下属成员加入（通常为您公司旗下门店的店长、营业员或财务人员等，该成员不具有独立账户及身份信息，您需对所邀请的成员的操作行为负责），并通过" +
          _vm._s(_vm.GLOBAL) +
          "服务实现门店管理等目的。您保证在遵守" +
          _vm._s(_vm.GLOBAL) +
          "服务规范的同时，您的成员按照您的授权范围和规范使用" +
          _vm._s(_vm.GLOBAL) +
          "服务，如您一旦丧失总新进账户及身份，" +
          _vm._s(_vm.GLOBAL) +
          "有权将基于该账户的服务一并停止。 4、用户转让或授权他人使用自己的" +
          _vm._s(_vm.GLOBAL) +
          "账户造成用户损失的，" +
          _vm._s(_vm.GLOBAL) +
          "平台及运营方不承担任何责任。 5、" +
          _vm._s(_vm.GLOBAL) +
          "用户必须是具有合法经营资格的实体组织，或者持有有效身份证明的18周岁以上具有完全民事行为能力的自然人。无经营或特定经营资格的组织或超过其民事权利或行为能力范围不得注册为" +
          _vm._s(_vm.GLOBAL) +
          "用户，如否，" +
          _vm._s(_vm.GLOBAL) +
          "平台有权不经过用户允许，采取取消使用、冻结或关闭账户、拒绝提供服务等措施，给" +
          _vm._s(_vm.GLOBAL) +
          "平台及相关方造成损失的，用户应承担赔偿责任。 6、免费使用服务中，" +
          _vm._s(_vm.GLOBAL) +
          "平台有权利继续保留或者停用" +
          _vm._s(_vm.GLOBAL) +
          "用户的账户。 "
      ),
      _c("div", [_vm._v("三、用户的权利与义务")]),
      _vm._v(
        " 1、用户有权根据本协议的约定，使用" +
          _vm._s(_vm.GLOBAL) +
          "平台提供的免费服务，包括但不仅限于：黄金报价查询、行情资讯、行业活动信息、新品发布、产品浏览、在线下单、在线查找等，具体以平台功能为准。 2、用户在" +
          _vm._s(_vm.GLOBAL) +
          "平台上按本平台服务流程所确认的交易状态将成为本平台为您进行相关交易或操作的明确指令。您同意" +
          _vm._s(_vm.GLOBAL) +
          "平台有权按相关指令依据本协议和/或有关文件和规则对相关事项进行处理。如用户未能及时对交易状态进行修改或确认或未能提交相关申请所引起的任何纠纷或损失由您本人负责，本平台及运营方不承担任何责任。 3、用户在使用" +
          _vm._s(_vm.GLOBAL) +
          "平台过程中实施的所有行为均应遵守中华人民共和国法律、法规、规范性文件及" +
          _vm._s(_vm.GLOBAL) +
          "平台各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。账户因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。账号持有人对其所有行为结果（包括但不限于在线签署各类协议、发布信息、及披露信息等）负责。如果违反上述承诺，产生任何法律后果的，用户应以自己的名义独立承担所有的法律责任，并确保总新进平台及运营方免于因此遭受任何损失 4、对于被" +
          _vm._s(_vm.GLOBAL) +
          "平台暂时停止或者永久停止账号的用户，停止账号期间" +
          _vm._s(_vm.GLOBAL) +
          "平台将不在该用户账号下提供任何服务。 5、对于任何影响或可能影响用户权利的情况，用户有权向" +
          _vm._s(_vm.GLOBAL) +
          "平台进行反馈，并提供相应证据资料，需要平台协助解决的，应提供有足够证明力的证据资料。 6、为了降低用户的运输成本，当用户运输货品给甲方，用户可以自行或委托" +
          _vm._s(_vm.GLOBAL) +
          "购买保险，或在" +
          _vm._s(_vm.GLOBAL) +
          "app端自行购买保险，委托" +
          _vm._s(_vm.GLOBAL) +
          "购买保险的，用户同意并指定" +
          _vm._s(_vm.GLOBAL) +
          "为保单的投保人和被保险人，并由" +
          _vm._s(_vm.GLOBAL) +
          "代收代付保险赔款，当货品发生保险事故并获得保险赔款后，双方将按照货品的各自所有权比例分摊赔款，同时" +
          _vm._s(_vm.GLOBAL) +
          "承诺在收到保险公司赔款并确定分摊金额后一个工作日支付赔款给用户。 "
      ),
      _c("div", [_vm._v("四、交易与费用条款")]),
      _vm._v(
        " 1、用户可以根据" +
          _vm._s(_vm.GLOBAL) +
          "平台有关规则和说明，通过本平台回收现货黄金等交易。详细操作规则及方式请见有关协议及本平台相关页面的规则和说明。 2、黄金产品的价格波动属性，因此，不同时间点的现货黄金价格应以" +
          _vm._s(_vm.GLOBAL) +
          "平台的实时金价报价为准。 3、" +
          _vm._s(_vm.GLOBAL) +
          "平台支持线下支付、第三方线上支付服务。 4、第三方线上支付所产生的转账手续费等，属于用户与第三方支付平台之间资费，收费参照各大银行及支付平台规定为准，此收费（若有）与" +
          _vm._s(_vm.GLOBAL) +
          "平台无关。 "
      ),
      _c("div", [_vm._v("五、隐私政策")]),
      _vm._v(
        " 1、用户必须遵守" +
          _vm._s(_vm.GLOBAL) +
          "平台的使用条款及隐私政策。 2、" +
          _vm._s(_vm.GLOBAL) +
          "平台可调用用户的信息资料（包括但不限于本平台持有的资料，以及从用户目前及以前在本平台上的活动所获取的其他资料）以识别产生或可能产生纠纷的问题、解决纠纷争议、以及对纠纷进行调解等事项，以确保用户在本平台进行安全交易。 3、" +
          _vm._s(_vm.GLOBAL) +
          "平台有权合法收集用户的使用习惯，进行平台优化及数据分析；也有权在用户同意下推送有针对性的信息及广告。 4、若由于交易及服务需要，部分业务" +
          _vm._s(_vm.GLOBAL) +
          "平台只起到撮合服务，如用户使用这些服务，即同意本平台将您的包括但不限于公司信息、真实姓名、联系方式等必要的公司及个人信息和交易信息披露给与您交易的另一方或本平台的合作机构（仅限于本平台为完成拟向您提供的服务而合作的机构，如物流公司、保险公司及其他金融企业等配套产品及服务的提供商等）。 5、" +
          _vm._s(_vm.GLOBAL) +
          "平台对用户的电子邮件、手机号、银行账户等隐私资料进行保护，承诺不会在未获得用户许可的情况下擅自将用户的个人资料信息出租或出售给任何第三方，但以下情况除外： • ① 用户同意让第三方共享资料； • ② 用户同意公开其用户资料，享受为其提供的产品和服务； • ③ 根据法律法规等有关规定，或者应行政、司法机构、专业事务机构、纠纷相对方的要求，向第三方或者行政、司法机构等披露。 "
      ),
      _c("div", [_vm._v("六、不可抗力")]),
      _vm._v(
        " 对于因" +
          _vm._s(_vm.GLOBAL) +
          "平台合理控制范围以外的原因，包括但不限于自然灾害、罢工、骚乱、暴动、战争行为、政府行为、通讯或其他设施故障或严重伤亡事故等不可抗力因素，致使" +
          _vm._s(_vm.GLOBAL) +
          "平台对用户的免费使用服务延迟或未能履约的，" +
          _vm._s(_vm.GLOBAL) +
          "平台不承担任何责任。 "
      ),
      _c("div", [_vm._v("七、协议修改")]),
      _vm._v(
        " 1、" +
          _vm._s(_vm.GLOBAL) +
          "平台可以修改本协议或任何适用于服务的附加条款。所有修改的适用不具有追溯力，且会在公布后生效。如果用户不同意服务的修改条款，有权选择停止使用服务或修改后继续使用。 2、如果本条款与附加条款有冲突，以附加条款为准。 "
      ),
      _c("div", [_vm._v("八、违约赔偿")]),
      _vm._v(
        " 1、用户同意保障和维护" +
          _vm._s(_vm.GLOBAL) +
          "平台及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给" +
          _vm._s(_vm.GLOBAL) +
          "平台或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。 2、如果用户不遵守本协议，且" +
          _vm._s(_vm.GLOBAL) +
          "平台未立即采取行动，并不意味" +
          _vm._s(_vm.GLOBAL) +
          "平台放弃可能享有的任何权利或在将来采取行动。 3、用户在" +
          _vm._s(_vm.GLOBAL) +
          "平台提交的定价订单需要在3天内发货 并且确保5个日达到本公司指定地址，如因价格变动未能及时发货，" +
          _vm._s(_vm.GLOBAL) +
          "有权要求用户缴纳订单总金额百分之10的保证金，如未缴纳保证金，并且超过5日货物未到达指定地址的，公司将追究相关法律责任，并有权提出相应的赔偿。 "
      ),
      _c("div", [_vm._v("九、特殊免责")]),
      _vm._v(
        " 1、用户已足够了解收集用户信息的相关规则、使用目的、必要性等，" +
          _vm._s(_vm.GLOBAL) +
          "平台对此已尽到足够的告知、提示。 2、" +
          _vm._s(_vm.GLOBAL) +
          "平台对链接到其他网站的隐私声明或其他内容不承担任何责任。 3、" +
          _vm._s(_vm.GLOBAL) +
          "平台中包含的由第三方提供的服务，" +
          _vm._s(_vm.GLOBAL) +
          "平台只是为了用户的便利而提供该功能模块。您如需使用此等服务，应另行与第三方服务提供方达成服务协议，支付相应费用并承担可能的风险。" +
          _vm._s(_vm.GLOBAL) +
          "平台及运营方对第三方提供的服务不提供任何形式的保证，用户与第三方任何纠纷与" +
          _vm._s(_vm.GLOBAL) +
          "平台及运营方无关。 4、如因升级的需要而需暂停服务、或调整服务内容，我们将尽可能在上进行通告。由于用户未能及时浏览通告而造成的损失，我们不承担任何责任。 5、" +
          _vm._s(_vm.GLOBAL) +
          "是一个开放平台，用户将文章或照片等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。作为网络服务的提供者，我们对用户在任何论坛、主页或其它互动区域提供的任何陈述、声明或内容均不承担责任。您明确同意使" +
          _vm._s(_vm.GLOBAL) +
          "服务所存在的风险或产生的一切后果将完全由您自身承担，我们对上述风险或后果不承担任何责任。 6、您违反本注册协议、违反道德或法律的，侵犯他人权利（包括但不限于知识产权）的，我们不承担任何责任。 7、对您、其他用户或任何第三方发布、存储或上传的任何图片、内容或由该等内容导致的任何损失或损害，我们不承担责任。 8、对黑客行为、计算机或手机病毒、或因您保管疏忽致使帐号、密码被他人非法使用、盗用、篡改的或丢失，或由于与本站链接的其它网站或所造成您资料的泄露，或您因其他非" +
          _vm._s(_vm.GLOBAL) +
          "原因造成的损失，我们不承担责任。如您发现任何非法使用用户帐号或安全漏洞的情况，请立即与我们联系。 9、因任何非" +
          _vm._s(_vm.GLOBAL) +
          "原因造成的网络服务中断或其他缺陷，我们不承担任何责任。 10、我们不保证服务一定能满足您的要求；不保证服务不会中断，也不保证服务的及时性、安全性、准确性。 "
      ),
      _c("div", [_vm._v("十、通知和送达")]),
      _vm._v(
        " 本协议项下所有的通知均通过" + _vm._s(_vm.GLOBAL) + "平台进行公告。 "
      ),
      _c("div", [_vm._v("十一、法律管辖和适用")]),
      _vm._v(
        " 1、本协议的订立、执行和解释及争议的解决均应适用中国法律。 2、如发生本协议与中国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它合法条款则依旧保持对用户产生法律效力和影响。 3、本协议的规定是可分割的，如本协议任何规定被裁定为无效或不可执行，该规定可被删除而其余条款应予以执行。 4、如" +
          _vm._s(_vm.GLOBAL) +
          "平台与用户双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向" +
          _vm._s(_vm.GLOBAL) +
          "平台的运营方，即：深圳" +
          _vm._s(_vm.GLOBAL) +
          "贵金属有限责任公司所在地有管辖权的人民法院提起诉讼。 "
      ),
      _c("div", [_vm._v("十二、其他规定")]),
      _vm._v(
        " 1、本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。 2、本协议未尽事宜，以使用规则为准，" +
          _vm._s(_vm.GLOBAL) +
          "平台对本活动规则享有最终解释权。 3、本协议中的标题仅为方便而设，不作为对标题下条款的解释。 4、" +
          _vm._s(_vm.GLOBAL) +
          " 平台的运营方有权根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异。 "
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }